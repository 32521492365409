// 缓存
/*
	token —— 令牌
	userInfo —— 用户信息 json字符串
*/

export default {
	DateFormaTime: function(date, formaText) {
		var formatNumber = function(n) {
			n = n.toString()
			return n[1] ? n : '0' + n
		}
		var year = date.getFullYear();
		var month = date.getMonth() + 1;
		var day = date.getDate();

		if (formaText == "YYYY-MM-DD") {
			return [year, month, day].map(formatNumber).join('-');
		}
		if (formaText == "YYYY-MM") {
			return [year, month].map(formatNumber).join('-');
		}
		if (formaText == "DD") {
			return day;
		}
		if (formaText == "YYYY") {
			return year;
		}
		if (formaText == "MM") {
			return month;
		}
	}
}
