<style scoped>

</style>
<template>
	<div class="con_page_container">
		<!-- 左边数据树 -->
		<div class="left_tree_box">
			<el-tree :props="props" node-key="id" ref="tree"  :load="loadNode" lazy 
			:expand-on-click-node="false" :highlight-current="true" @node-click="treeClick" @node-expand="nodeExpand"></el-tree>
		</div>
		<!-- 内容 -->
		<div class="main_container">
			<div class="ch_temp_box">
				<index v-if="showIndex" :subPageParams="subPageParams" v-on:childrenEvent="indexEvent" ></index>
			</div>
		</div>
	</div>
</template>

<script>
	import index from './mainIndex.vue';
	var _this;
	export default {
		components:{
			index
		},
		data() {
			return {
				communityList:[],//小区列表
				treeCurrentNodeKey:0,
				props: {
					label: 'name',
					children: 'zones',
					isLeaf: 'leaf'
				},
				showIndex:false,
				subPageParams:null
			}
		},
		created: function() {
			_this = this;
		},
		mounted: function() {

		},
		methods: {
			/* * 获取小区列表 tree加载 */
			loadNode(node, resolve) {
				if (node.level === 0) {
					//获取小区
					_this._getApi('/wy/account/getResidenceListByAdmin', {}).then((res) => {
						if (res.code == 1) {
							if(res.data.length == 0){
								return;
							}
							_this.communityList = res.data;
							_this.subPageParams = res.data[0];
							_this.$nextTick(function(){
								_this.$refs.tree.setCurrentKey(_this.communityList[0].id);//获取已经设置的资源后渲染
							})
							_this.showIndex = true;
							var baseData = _this.communityList;
							baseData.forEach(function(item){
								item.leaf = true;
							})
							return resolve(baseData);
							
						} else {
					
						}
					}).catch((err) => {})
				}
			},
			
			/* * 节点被展开 */
			nodeExpand(data,node,_this_){
				
			},
			/* * tree 点击 */
			treeClick(data,node,_this_){
				// 小区被点击
				if(node.level == 1){
					_this.subPageParams = data;
					_this.showIndex = false;
					_this.$nextTick(function(){
						_this.showIndex = true;
					})
				}
			},
			
			/* * 楼宇 组件通信 */
			indexEvent(e){
				switch(e.type){
					case 'add':
						var node = _this.$refs.tree.getNode(_this.selectCommunityId);   //要刷新的tree id
						node.loaded = false;
						node.expand();
					break;
				}
			},
			
		}
	}
</script>
