<style scoped="202104281256">
	.complete-box {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
	}
	.body-box {
		flex: 1;
	}
	.head-box {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #ccc;
	}
	.head-box-item {
		flex: 1;
	}
	.all-item {
		display: flex;
		flex-direction: row;
		padding: 5px 0;
	}
	.all-item .all-item-label:nth-child(1) {
		padding-right: 10px;
		width: 40%;
		text-align: right;
	}
	.head-box .head-box-item:nth-child(2) {
		border-left: 1px solid #ccc;
		border-right: 1px solid #ccc;
	}
	.body-box {
		flex: 1;
		padding-top: 30px;
	}
	.row-info {
		display: flex;
		flex-direction: row;
		border-bottom: 1px solid #ccc;
		padding: 5px 0;
	}
	.row-info .row-info-title:nth-child(1), .row-info .row-info-title:nth-child(3) {
		/* width: 10rem; */
		text-align: right;
	}
	.row-info-title {
		/* width: 8rem; */
		padding-left: 10px;
	}
	.function-item {
		display: flex;
		flex-direction: row;
		padding: 15px 0;
		align-items: center;
	}
	.function-item .function-item-label:nth-child(1) {
		text-align: right;
	}
	.function-item-label {
		padding-left: 10px;
	}
</style>

<template>
	<div class="complete-box">
		<div class="head-box" v-if="pageData">
			<div class="head-box-item">
				<div class="all-item">
					<div class="all-item-label">今年应收</div>
					<div class="all-item-label">{{pageData.receivable.money}}元</div>
				</div>
				<div class="all-item" v-for="item in pageData.receivable.list">
					<div class="all-item-label">{{item.item_name}}:</div>
					<div class="all-item-label">{{item.ys_fy}}元</div>
				</div>
			</div>
			<div class="head-box-item">
				<div class="all-item">
					<div class="all-item-label">今年已收</div>
					<div class="all-item-label">{{pageData.received.money}}元</div>
				</div>
				<div class="all-item" v-for="item in pageData.received.list">
					<div class="all-item-label">{{item.item_name}}:</div>
					<div class="all-item-label">{{item.ys_fy}}元</div>
				</div>
			</div>
			<div class="head-box-item">
				<div class="all-item">
					<div class="all-item-label">欠费合计（已发布）</div>
					<div class="all-item-label">{{pageData.arrears.money}}元</div>
				</div>
				<div class="all-item" v-for="item in pageData.arrears.list">
					<div class="all-item-label">{{item.item_name}}:</div>
					<div class="all-item-label">{{item.ys_fy}}元</div>
				</div>
			</div>
		</div>
		<div class="body-box">
			<div class="function-item">
				<div class="function-item-label">应收明细导出</div>
				<div class="function-item-label">
					<el-date-picker v-model="ys.start_month" type="month" size="small" placeholder="开始年月">
					</el-date-picker>
					至
					<el-date-picker v-model="ys.end_month" type="month" size="small" placeholder="截止年月">
					</el-date-picker>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="ysDetail()">导出</el-button>
				</div>
			</div>
			<div class="function-item">
				<div class="function-item-label">已收明细导出</div>
				<div class="function-item-label">
					<el-date-picker v-model="yiShouParma.start_month" type="month" size="small" placeholder="开始年月">
					</el-date-picker>
					至
					<el-date-picker v-model="yiShouParma.end_month" type="month" size="small" placeholder="截止年月">
					</el-date-picker>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="yiShou()">导出</el-button>
				</div>
			</div>
			<div class="function-item">
				<div class="function-item-label">欠费明细导出</div>
				<div class="function-item-label">
					<el-date-picker v-model="arrearsDetails.start_month" type="month" size="small" placeholder="开始年月">
					</el-date-picker>
					至
					<el-date-picker v-model="arrearsDetails.end_month" type="month" size="small" placeholder="截止年月">
					</el-date-picker>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="qfDetailed()">导出</el-button>
				</div>
			</div>
			<div class="function-item">
				<div class="function-item-label">缴费汇总导出</div>
				<div class="function-item-label">
					<el-date-picker v-model="paymentSummaryParam.pay_date_start" type="date" size="small" placeholder="开始年月日">
					</el-date-picker>
					至
					<el-date-picker v-model="paymentSummaryParam.pay_date_end" type="date" size="small" placeholder="截止年月日">
					</el-date-picker>
				</div>
				<div class="function-item-label">
					<el-button type="success" size="small" @click="paymentSummaryDown()">导出</el-button>
				</div>
			</div>

			<el-divider></el-divider>



			<div class="function-item" style="display: flex;">
				<div class="function-item">
					<div class="function-item-label">费用期间应收汇总</div>
					<!-- <div class="function-item-label">
						<el-date-picker v-model="nianduYingShouParam.year" type="year" size="small" placeholder="选择年份">
						</el-date-picker>
					</div> -->
					<div class="function-item-label">
						<el-button type="success" size="small" @click="nianduYingShouOpen()">查看</el-button>
					</div>
				</div>
				<el-divider direction="vertical"></el-divider>
				<div class="function-item">
					<div class="function-item-label">费用期间已收汇总</div>
					<!-- <div class="function-item-label">
						<el-date-picker v-model="nianduYiShouParam.year" type="year" size="small" placeholder="选择年份">
						</el-date-picker>
					</div> -->
					<div class="function-item-label">
						<el-button type="success" size="small" @click="nianduYiShouOpen()">查看</el-button>
					</div>
				</div>
				<el-divider direction="vertical"></el-divider>
				<div class="function-item">
					<div class="function-item-label">费用期间欠费汇总</div>
					<!-- <div class="function-item-label">
						<el-date-picker v-model="nianduHuiZongParam.start_year" type="year" size="small" placeholder="开始年份">
						</el-date-picker>
						至
						<el-date-picker v-model="nianduHuiZongParam.end_year" type="year" size="small" placeholder="截止年份">
						</el-date-picker>
					</div> -->
					<div class="function-item-label">
						<el-button type="success" size="small" @click="nianduHuiZongOpen()">查看</el-button>
					</div>
				</div>
			</div>

		</div>

		<!-- 年度应收汇总查看 -->
		<el-dialog custom-class="my_dialog" width="95%" top="none" title="费用期间应收汇总查看"
			:visible.sync="nianduYingShouParam.show" destroy-on-close>
			<nianduYingShou v-if="nianduYingShouParam.show" :pageParam="nianduYingShouParam.pageParams" v-on:childrenEvent="nianduYingShouEvent">
			</nianduYingShou>
		</el-dialog>
		<!-- 年度已收汇总查看 -->
		<el-dialog custom-class="my_dialog" width="95%" top="none" title="费用期间已收汇总查看"
			:visible.sync="nianduYiShouParam.show" destroy-on-close>
			<nianduYiShou v-if="nianduYiShouParam.show" :pageParam="nianduYiShouParam.pageParams" v-on:childrenEvent="nianduYiShouEvent">
			</nianduYiShou>
		</el-dialog>
		<!-- 年度欠费汇总查看 -->
		<el-dialog custom-class="my_dialog" width="95%" top="none" title="费用期间欠费汇总查看"
			:visible.sync="nianduHuiZongParam.show" destroy-on-close>
			<nianduHuiZong v-if="nianduHuiZongParam.show" :pageParam="nianduHuiZongParam.pageParams" v-on:childrenEvent="nianduHuiZongEvent">
			</nianduHuiZong>
		</el-dialog>
	</div>
</template>

<script>
	import nianduYingShou from "../nianduYingShou/nianduYingShou.vue";
	import nianduYiShou from "../nianduYiShou/nianduYiShou.vue";
	import nianduHuiZong from "../nianduHuiZong/nianduHuiZong.vue";
	import utils from '@/util/common.js'
	var _this;
	export default {
		components: {
			"nianduYingShou": nianduYingShou,
			"nianduYiShou": nianduYiShou,
			"nianduHuiZong":nianduHuiZong
		},
		props: ['subPageParams'],
		data() {
			return {
				pageData: null,
				// 应收
				ys: {
					start_month: '',
					end_month: ''
				},
				// 已收
				yiShouParma: {
					start_month: '',
					end_month: ''
				},
				// 欠费明细时间
				arrearsDetails: {
					start_month: '',
					end_month: ''
				},
				// 年度应收
				nianduYingShouParam: {
					year: '',
					show: false,
					pageParams: null
				},
				// 年度已收
				nianduYiShouParam: {
					year: '',
					show: false,
					pageParams: null
				},
				// 年度欠费汇总导出
				nianduHuiZongParam: {
					start_year: '',
					end_year: '',
					show: false,
					pageParams: null
				},
				// 缴费汇总导出
				paymentSummaryParam:{
					pay_date_start:'',
					pay_date_end:''
				}
			}
		},
		created: function() {
			_this = this;
			_this.getPageData();
			// console.log(_this.subPageParams)
		},
		mounted: function() {

		},
		methods: {
			/**
			 * @discribe 获取数据
			 * @author:郑凯
			 * @create_time:2021-4-30 15:03:06
			 */
			getPageData() {
				_this.loading = true;
				var params = {};
				params.residence_id = _this.subPageParams.id;
				_this._postApi('/wy/data/finance/getChargeInfo', params)
					.then(res => {
						if (res.code == 1) {
							_this.pageData = res.data;
						} else {
							_this
								.$confirm(res.msg, '提示', {
									confirmButtonText: '确定',
									cancelButtonText: '取消',
									type: 'warning'
								})
								.then(() => {})
								.catch(() => {});
						}
						_this.loading = false;
					})
					.catch(err => {
						console.log(err);
					});
			},

			/**
			 * @discribe 应收明细导出
			 * @author:郑凯
			 * @create_time:2021-4-30 14:22:57
			 */
			ysDetail() {
				if (_this.ys.start_month == '' || _this.ys.end_month == '') {
					_this.$confirm("请选择月份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let start_month = utils.DateFormaTime(_this.ys.start_month, "YYYY-MM");
				let end_month = utils.DateFormaTime(_this.ys.end_month, "YYYY-MM");
				_this.customDownload({
					url: '/wy/data/finance/costYSDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + start_month + '-' + end_month + ' ' + '应收明细.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						start_month: utils.DateFormaTime(_this.ys.start_month, "YYYY-MM"),
						end_month: utils.DateFormaTime(_this.ys.end_month, "YYYY-MM")
					}
				})
			},

			/**
			 * @discribe 已收
			 * @author:郑凯
			 * @create_time:2021-4-30 14:42:40
			 */
			yiShou() {
				if (_this.yiShouParma.start_month == '' || _this.yiShouParma.end_month == '') {
					_this.$confirm("请选择月份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let start_month = utils.DateFormaTime(_this.yiShouParma.start_month, "YYYY-MM");
				let end_month = utils.DateFormaTime(_this.yiShouParma.end_month, "YYYY-MM");
				_this.customDownload({
					url: '/wy/data/finance/costYJDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + start_month + '-' + end_month + ' ' + '已收明细.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						start_month: utils.DateFormaTime(_this.yiShouParma.start_month, "YYYY-MM"),
						end_month: utils.DateFormaTime(_this.yiShouParma.end_month, "YYYY-MM")
					}
				})
			},

			/**
			 * @discribe 欠费明细导出
			 * @author:郑凯
			 * @create_time:2021-4-29 10:51:22
			 */
			qfDetailed() {
				if (_this.arrearsDetails.start_month == '' || _this.arrearsDetails.end_month == '') {
					_this.$confirm("请选择月份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let start_month = utils.DateFormaTime(_this.arrearsDetails.start_month, "YYYY-MM");
				let end_month = utils.DateFormaTime(_this.arrearsDetails.end_month, "YYYY-MM");
				_this.customDownload({
					url: '/wy/data/finance/costQFDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + start_month + '-' + end_month + ' ' + '欠费明细.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						start_month: utils.DateFormaTime(_this.arrearsDetails.start_month, "YYYY-MM"),
						end_month: utils.DateFormaTime(_this.arrearsDetails.end_month, "YYYY-MM")
					}
				})
			},

			/**
			 * @discribe 年度应收汇总
			 * @author:郑凯
			 * @create_time:2021-4-30 14:47:13
			 */
			nianduYingShou() {
				if (_this.nianduYingShouParam.year == '') {
					_this.$confirm("请选择年份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let year = utils.DateFormaTime(_this.nianduYingShouParam.year, "YYYY");
				_this.customDownload({
					url: '/wy/data/finance/costNDYSDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + year + '' + ' ' + '年度应收汇总.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						year: year
					}
				})
			},

			/**
			 * @discribe 年度已收
			 * @author:郑凯
			 * @create_time:2021-4-30 14:55:53
			 */
			nianduYiShou() {
				if (_this.nianduYiShouParam.year == '') {
					_this.$confirm("请选择年份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let year = utils.DateFormaTime(_this.nianduYiShouParam.year, "YYYY");
				_this.customDownload({
					url: '/wy/data/finance/costNDSRDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + year + '' + ' ' + '年度已收汇总.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						year: year
					}
				})
			},

			/**
			 * @discribe 年度汇总
			 * @author:郑凯
			 * @create_time:2021-4-30 14:57:12
			 */
			nianduHuiZong() {
				if (_this.nianduHuiZongParam.start_year == '' || _this.nianduHuiZongParam.end_year == '') {
					_this.$confirm("请选择年份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let start_year = utils.DateFormaTime(_this.nianduHuiZongParam.start_year, "YYYY");
				let end_year = utils.DateFormaTime(_this.nianduHuiZongParam.end_year, "YYYY");
				_this.customDownload({
					url: '/wy/data/finance/costNDQFDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + start_year + '年-' + end_year + '年 ' + '欠费明细.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						start_year: start_year,
						end_year: end_year
					}
				})
			},

			/**
			* @discribe 缴费汇总导出
			* @author:zk
			* @create-time:2021-7-29 2:34:54 ?F10: PM?
			*/
		    paymentSummaryDown(){
				if (_this.paymentSummaryParam.pay_date_start == '' || _this.paymentSummaryParam.pay_date_end == '') {
					_this.$confirm("请选择年份", '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {}).catch(() => {});
					return;
				}
				let pay_date_start = utils.DateFormaTime(_this.paymentSummaryParam.pay_date_start, "YYYY-MM-DD");
				let pay_date_end = utils.DateFormaTime(_this.paymentSummaryParam.pay_date_end, "YYYY-MM-DD");
				_this.customDownload({
					url: '/wy/data/finance/dataDown',
					type: 'application/vnd.ms-excel;charset-UTF-8',
					fileName: _this.subPageParams.name + ' ' + pay_date_start + '-' + pay_date_end + '缴费汇总数据.xlsx',
					params: {
						residence_id: _this.subPageParams.id,
						pay_date_start: pay_date_start,
						pay_date_end: pay_date_end
					}
				})
			},


			/**
			 * @discribe 下载
			 * @author:郑凯
			 * @create_time:2021-4-29 10:49:34
			 */
			customDownload(option) {
				_this._axios({
					method: 'get',
					url: option.url,
					params: option.params,
					headers: {
						"token": localStorage.getItem('token')
					},
					responseType: 'arraybuffer'
				}).then((response) => {
					// console.log(response.getResponseHeaders('content-type'));
					//console.log(response)
					let blob = new Blob([response.data], {
						// type: 'application/vnd.ms-excel;charset-UTF-8'
						// type: 'application/zip',
						type: option.type
					})
					let downloadElement = document.createElement('a');
					let href = window.URL.createObjectURL(blob); //创建下载的链接
					downloadElement.href = href;
					downloadElement.download = option.fileName; //下载后文件名
					document.body.appendChild(downloadElement);
					downloadElement.click(); //点击下载
					document.body.removeChild(downloadElement); //下载完成移除元素
					window.URL.revokeObjectURL(href); //释放掉blob对象
				}).catch(() => {

				});
			},

			/**
			 * @discribe 年度应收查看
			 * @author:郑凯
			 * @create_time:2021-5-11 10:52:52
			 */
			nianduYingShouOpen() {
				_this.nianduYingShouParam.pageParams = {
					community: {
						id: _this.subPageParams.id,
						type: 0
					}
				}
				_this.nianduYingShouParam.show = true;
			},
			nianduYingShouEvent() {
				_this.nianduYingShouParam.show = false;
			},
			/**
			 * @discribe 年度已收查看
			 * @author:郑凯
			 * @create_time:2021-5-11 10:52:52
			 */
			nianduYiShouOpen() {
				_this.nianduYiShouParam.pageParams = {
					community: {
						id: _this.subPageParams.id,
						type: 0
					}
				}
				_this.nianduYiShouParam.show = true;
			},
			nianduYiShouEvent() {
				_this.nianduYiShouParam.show = false;
			},
			/**
			 * @discribe 年度欠费查看
			 * @author:郑凯
			 * @create_time:2021-5-11 10:52:52
			 */
			nianduHuiZongOpen() {
				_this.nianduHuiZongParam.pageParams = {
					community: {
						id: _this.subPageParams.id,
						type: 0
					}
				}
				_this.nianduHuiZongParam.show = true;
			},
			nianduHuiZongEvent() {
				_this.nianduHuiZongParam.show = false;
			},
		}
	}
</script>
