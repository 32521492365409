<style scoped>
	div, input, textarea {
		box-sizing: border-box;
	}
	.page_box_add_community {
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.page_box_body {
		overflow: auto;
		flex: 1;
	}
	.page_box_footer {
		padding: 10px 10px 0 10px;
		text-align: right;
	}
	/* 表单 */
	.z_form_item_row {
		padding: 10px 0;
	}
	.z_form_item {
		flex: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
	}
	.z_form_item_label {
		width: 100px;
		text-align: right;
		padding-right: 10px;
	}
	.z_form_item_value {
		flex: 1;
	}
	.z_form input {
		height: 30px;
		width: 100%;
	}
	.z_form textarea {
		height: 80px;
		width: 100%;
		padding: 5px;
	}
</style>
<style>
	.z_form .el-form-item {
		margin-bottom: 0;
	}
	.z_form .el-form-item__content {
		margin-left: 0 !important;
	}
</style>
<template>
	<div class="page_box_add_community">
		<div class="page_box_header" style="padding-bottom: 20px;">
			<el-date-picker v-model="start_month" type="month" size="small" placeholder="开始年月" @change="startMonthhandler">
			</el-date-picker>
			至
			<el-date-picker v-model="end_month" type="month" size="small" placeholder="截止年月" @change="endMonthhandler">
			</el-date-picker>

			<el-select v-model="builds" multiple placeholder="请选择" size="small" style="margin-left: 20px;width: 600px;"
				@change="buildsHandler">
				<el-option v-for="item in buildOptions" :key="item.id" :label="item.name" :value="item.id">
				</el-option>
			</el-select>
		</div>
		<div class="page_box_body">
			<div class="z_form">
				<el-table :data="tableData" border style="width: 100%" :height="tableH">
					<!-- <el-table-column prop="build_name"></el-table-column> -->
					<el-table-column v-for="item in tableDataHeader" v-bind:key="item.prop" :property="item.prop"
						:label="item.item_name">

						<el-table-column v-for="item1,index in item.children" v-bind:key="item1.item_name"
							:property="item.item_name" :label="item1.item_name+'/元'">
							<template slot-scope="{scope,$index}">
								{{tableData[$index].item[index]?tableData[$index].item[index].data:0}}
							</template>
						</el-table-column>
					</el-table-column>
				</el-table>
			</div>
		</div>
		<div class="page_box_footer">
			<el-button @click="close()">关闭</el-button>
			<!-- <el-button type="success"  @click="onSubmit">立即修改</el-button> -->
		</div>
	</div>
</template>

<script>
	import utils from '@/util/common.js'
	var _this;
	export default {
		props: ["pageParam"],
		data() {
			return {
				tableH: '',
				start_month: '',
				end_month: '',
				start_month_text: '',
				end_month_text: '',
				tableHeaderLabel: '',
				tableData: [],
				tableDataHeader: [],
				buildOptions: [],
				builds: []
			}
		},
		created: function() {
			_this = this;
			_this.getBuildData();
			_this.tableH = document.body.offsetHeight - 200 + 'px';
			_this.end_month = new Date();
			_this.start_month = new Date(new Date().getFullYear() - 1, new Date().getMonth() + 1, new Date()
				.getDate());
			_this.getTableData();
		},
		methods: {
			/**   
			 * @discribe 获取楼栋数据
			 * @author:郑凯 
			 * @create_time:2021-5-11 14:28:53 
			 */
			getBuildData() {
				_this._getApi('/wy/residenceBuild/getList', {
					residence_id: _this.pageParam.community.id
				}).then((res) => {
					if (res.code == 1) {
						let basedata = res.data.list;
						_this.buildOptions = basedata;
						basedata.forEach(function(item) {
							_this.builds.push(item.id)
						})
					} else {
						_this.btnSaveLoading = false;
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			},
			buildsHandler() {
				_this.getTableData();
			},

			startMonthhandler(val) {
				_this.start_month = val;
				_this.getTableData();
			},

			endMonthhandler(val) {
				_this.end_month = val;
				_this.getTableData();
			},
			/**   
			 * @discribe 获取数据
			 * @author:郑凯 
			 * @create_time:2021-5-11 11:11:06 
			 */
			getTableData() {
				let start_month = utils.DateFormaTime(_this.start_month, "YYYY-MM");
				let end_month = utils.DateFormaTime(_this.end_month, "YYYY-MM");
				_this.start_month_text = start_month;
				_this.end_month_text = end_month;
				_this.tableHeaderLabel = '' + start_month + '至' + end_month + '欠费费用';
				_this._postApi('/wy/data/finance/costNDHZ', {
					residence_id: _this.pageParam.community.id,
					start_month: start_month,
					end_month: end_month,
					builds: _this.builds,
					type: 1,
				}).then((res) => {
					if (res.code == 1) {
						let basedata = res.data;
						let cusTabdata = [];
						cusTabdata.push({
							item_name: "楼栋",
							prop: 'build_name'
						})
						cusTabdata.push({
							item_name: "应收户数",
							prop: 'ys_hs'
						})
						let iobj = [];
						iobj = basedata[0].item;
						iobj.forEach(function(item) {
							item.prop = "item.data"
						})

						cusTabdata.push({
							item_name: _this.tableHeaderLabel,
							children: iobj
						})

						_this.tableData = basedata;
						_this.tableDataHeader = cusTabdata;
					} else {
						_this.$confirm(res.msg, '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {

						}).catch(() => {

						});
					}
				}).catch((err) => {

				})
			},

			/**   
			 * 关闭
			 * author:郑凯 
			 * create_time:2021-3-11 15:27:08 
			 */
			close() {
				_this.$emit('childrenEvent', '');
			}
		}
	}
</script>
